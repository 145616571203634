import type { SxProps } from "@mui/material";
import { Box, keyframes } from "@mui/material";
import type { ReactNode } from "react";

export function BounceInWidth({
  width = "22px",
  widthOver = "24px",
  widthUnder = "21px",
  children,
  sx,
  duration,
}: {
  width?: string;
  widthOver?: string;
  widthUnder?: string;
  children: ReactNode;
  sx?: SxProps;
  duration?: string;
}) {
  return (
    <Box
      sx={{
        "--width": width,
        "--width-over": widthOver,
        "--width-under": widthUnder,
        animation: `${bounceInWidth} ${duration || "0.5s"} ease-in-out`,
        ...sx,
      }}
      component="span"
    >
      {children}
    </Box>
  );
}

const bounceInWidth = keyframes`
  from, 20%, 55%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    width: 0;
  }

  20% {
    width: 0;
  }

  55% {
    width: var(--width-over);
  }

  90% {
    width: var(--width-under);
  }

  to {
    width: var(--width);
  }
`;
